import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Flex, Box, Heading, VStack, HStack, Button, useBreakpointValue, Spinner, Checkbox, CheckboxGroup,
  Select, FormControl, FormLabel, FormHelperText, Grid, GridItem, Text, Slider, SliderTrack, SliderFilledTrack, SliderThumb,
  Radio, RadioGroup, useColorModeValue, Menu, MenuButton, MenuList, MenuItem,
  Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
  useDisclosure, Stack, Switch, NumberInput, NumberInputField, NumberInputStepper,
  NumberIncrementStepper, NumberDecrementStepper, Textarea, Input
} from '@chakra-ui/react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer,
  AreaChart, Area, BarChart, Bar, ScatterChart, Scatter, PieChart, Pie, ComposedChart, Cell, Tooltip,
} from 'recharts';
import { stockDataManager } from './mockData';
import { FaChartLine, FaChartBar, FaChartPie, FaChartArea, FaDotCircle, FaTree } from 'react-icons/fa';
import { IoMdSettings } from 'react-icons/io';
import { Parser } from 'expr-eval';


const DataVisualization = () => {
  const [priceData, setPriceData] = useState([]);
  const [volumeData, setVolumeData] = useState([]);
  const [selectedStocks, setSelectedStocks] = useState([]);
  const [stockOptions, setStockOptions] = useState([]);
  const [dateRange, setDateRange] = useState({ minDate: new Date(), maxDate: new Date() });
  const [chartType, setChartType] = useState('line');
  const [isLoading, setIsLoading] = useState(true);
  const [comparisonStock, setComparisonStock] = useState('');
  const [timeFilter, setTimeFilter] = useState('all');
  const [liquidityThreshold, setLiquidityThreshold] = useState(1000000);
  const [marketCapThreshold, setMarketCapThreshold] = useState(1000000000);
  const [momentumPeriod, setMomentumPeriod] = useState(6);
  const [portfolioSize, setPortfolioSize] = useState(10);
  const [portfolioValue, setPortfolioValue] = useState(1000000);
  const [weightingMethod, setWeightingMethod] = useState('equal');
  const [showMeanValue, setShowMeanValue] = useState(false);
  const [secondaryChartType, setSecondaryChartType] = useState('radar');
  const [colorScheme, setColorScheme] = useState('default');
  const [dataUpdateInterval, setDataUpdateInterval] = useState(60000);
  const [showOutliers, setShowOutliers] = useState(false);
  const [correlationThreshold, setCorrelationThreshold] = useState(0.7);
  const [riskTolerance, setRiskTolerance] = useState('medium');
  const [rebalancingFrequency, setRebalancingFrequency] = useState('monthly');
  const [benchmarkIndex, setBenchmarkIndex] = useState('');
  const [showTrendlines, setShowTrendlines] = useState(false);
  const [volumeWeightedPrice, setVolumeWeightedPrice] = useState(false);
  const [movingAveragePeriods, setMovingAveragePeriods] = useState([20, 50, 200]);
  const [technicalIndicators, setTechnicalIndicators] = useState([]);
  const [fundamentalFactors, setFundamentalFactors] = useState([]);
  const [customFormulas, setCustomFormulas] = useState([]);
  const [selectedFormulaId, setSelectedFormulaId] = useState(null);
  const [dataTransformations, setDataTransformations] = useState([]);
  const [parsedCustomFormulas, setParsedCustomFormulas] = useState([]);
  const [annotationMode, setAnnotationMode] = useState(false);
  const [annotations, setAnnotations] = useState([]);
  const [alertThresholds, setAlertThresholds] = useState({});
  const [selectedTimeZone, setSelectedTimeZone] = useState('UTC');
  const [currencyConversion, setCurrencyConversion] = useState('USD');
  const [darkMode, setDarkMode] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [dataExportFormat, setDataExportFormat] = useState('csv');
  const [heatmapMetric, setHeatmapMetric] = useState('correlation');
  const [fundamentalData, setFundamentalData] = useState({});
  const [cumulativeData, setCumulativeData] = useState([]);
  const [weights, setWeights] = useState({});
  const [customFormulaErrors, setCustomFormulaErrors] = useState([]);




  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const [fullDateRange, setFullDateRange] = useState({ minDate: new Date(), maxDate: new Date() });


  
  

  useEffect(() => {
    const initializeData = async () => {
      await stockDataManager.loadData();
      const stocks = stockDataManager.getStockList();
      setStockOptions(stocks);
      setSelectedStocks([stocks[0]]);
      const dateRange = stockDataManager.getDateRange();
      setFullDateRange(dateRange);
      setIsLoading(false);
    };
  
    initializeData();
  }, []);
  

  

  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      if (autoRefresh) {
        fetchData();
      }
    }, dataUpdateInterval);

    return () => clearInterval(fetchDataInterval);
  }, [autoRefresh, dataUpdateInterval]);


  const fetchFundamentalData = useCallback(async () => {
    const data = {};
  
    for (const stock of selectedStocks) {
      const stockData = stockDataManager.getFundamentalData(stock);
      data[stock] = stockData;
    }
  
    setFundamentalData(data);
  }, [selectedStocks]);
  


  useEffect(() => {
    fetchFundamentalData();
  }, [fetchFundamentalData]);
  

 

  const calculateDateRange = useCallback(() => {
    const now = new Date();
    let minDate;
    switch (timeFilter) {
      case '1d':
        minDate = new Date(now);
        minDate.setDate(now.getDate() - 1);
        break;
      case '1w':
        minDate = new Date(now);
        minDate.setDate(now.getDate() - 7);
        break;
      case '1m':
        minDate = new Date(now);
        minDate.setMonth(now.getMonth() - 1);
        break;
      case '3m':
        minDate = new Date(now);
        minDate.setMonth(now.getMonth() - 3);
        break;
      case '6m':
        minDate = new Date(now);
        minDate.setMonth(now.getMonth() - 6);
        break;
      case '1y':
        minDate = new Date(now);
        minDate.setFullYear(now.getFullYear() - 1);
        break;
      case '5y':
        minDate = new Date(now);
        minDate.setFullYear(now.getFullYear() - 5);
        break;
      default:
        minDate = fullDateRange.minDate;
    }
    const maxDate = now;
    return { minDate, maxDate };
  }, [timeFilter, fullDateRange]);
  

 
  
  const applyDataTransformations = (data) => {
    let transformedData = [...data];
  
    // Identify the keys for price data
    const priceKeys = selectedStocks.map((stock) => stock);
  
    // Identify the keys for VWAP data
    const vwapKeys = selectedStocks.map((stock) => `${stock}_VWAP`);
  
    // Apply transformations to price data
    if (dataTransformations.length > 0) {
      transformedData = transformedData.map((row, index, array) => {
        const transformedRow = { ...row };
        priceKeys.forEach((key) => {
          // Apply transformations to price data
          if (dataTransformations.includes('log')) {
            transformedRow[key] = row[key] > 0 ? Math.log(row[key]) : null;
          }
          if (dataTransformations.includes('percent') && index > 0) {
            const prevValue = array[index - 1][key];
            transformedRow[key] = prevValue !== 0 ? ((row[key] - prevValue) / prevValue) * 100 : null;
          }
          if (dataTransformations.includes('normalized')) {
            const baseValue = array[0][key];
            transformedRow[key] = baseValue !== 0 ? (row[key] / baseValue) * 100 : null;
          }
        });
        return transformedRow;
      });
    }
  
    // Apply transformations to VWAP data if VWAP is calculated
    if (volumeWeightedPrice && dataTransformations.length > 0) {
      transformedData = transformedData.map((row, index, array) => {
        const transformedRow = { ...row };
        vwapKeys.forEach((key) => {
          // Apply transformations to VWAP data
          if (dataTransformations.includes('log')) {
            transformedRow[key] = row[key] > 0 ? Math.log(row[key]) : null;
          }
          if (dataTransformations.includes('percent') && index > 0) {
            const prevValue = array[index - 1][key];
            transformedRow[key] = prevValue !== 0 ? ((row[key] - prevValue) / prevValue) * 100 : null;
          }
          if (dataTransformations.includes('normalized')) {
            const baseValue = array[0][key];
            transformedRow[key] = baseValue !== 0 ? (row[key] / baseValue) * 100 : null;
          }
        });
        return transformedRow;
      });
    }
  
    return transformedData;
  };
  




  const getSelectedFormula = () => {
    return customFormulas.find((formula) => formula.id === selectedFormulaId);
  };

  
  const handleAddNewFormula = () => {
    const newFormula = {
      id: Date.now().toString(), // Simple unique ID
      name: `Formula ${customFormulas.length + 1}`,
      formula: '',
    };
    setCustomFormulas([...customFormulas, newFormula]);
    setSelectedFormulaId(newFormula.id);
  };

  const handleFormulaNameChange = (e) => {
    const updatedFormulas = customFormulas.map((formula) =>
      formula.id === selectedFormulaId
        ? { ...formula, name: e.target.value }
        : formula
    );
    setCustomFormulas(updatedFormulas);
  };

  
  const handleFormulaContentChange = (e) => {
    const updatedFormulas = customFormulas.map((formula) =>
      formula.id === selectedFormulaId
        ? { ...formula, formula: e.target.value }
        : formula
    );
    setCustomFormulas(updatedFormulas);
  };

  const handleApplyFormula = () => {
    // Parse and validate the formulas
    const { parsedFormulas, errors } = parseCustomFormulas();
  
    // Update the errors state
    setCustomFormulaErrors(errors);
  
    if (errors.length === 0) {
      // No errors, update the parsed formulas
      setParsedCustomFormulas(parsedFormulas);
  
      // Re-fetch data to apply the new formulas
      fetchData();
    } else {
      // Errors exist, do not update parsed formulas
      // Errors will be displayed to the user
    }
  };
  
  

  const handleDeleteFormula = () => {
    const updatedFormulas = customFormulas.filter(
      (formula) => formula.id !== selectedFormulaId
    );
    setCustomFormulas(updatedFormulas);
    setSelectedFormulaId(null);
  
    // Re-parse the remaining formulas and update errors
    const { parsedFormulas, errors } = parseCustomFormulas(updatedFormulas);
    setCustomFormulaErrors(errors);
    setParsedCustomFormulas(parsedFormulas);
  
    // Re-fetch data to apply the updated formulas
    fetchData();
  };
  
  
  
  
  const parseCustomFormulas = (formulas = customFormulas) => {
    const parser = new Parser();
    const parsedFormulas = [];
    const errors = [];
  
    formulas.forEach((formulaObj) => {
      const { formula, name } = formulaObj;
      const trimmedFormula = formula.trim();
      if (trimmedFormula === '') {
        errors.push(`Formula "${name}" is empty.`);
        return;
      }
      try {
        const parsedFormula = parser.parse(trimmedFormula);
        const variables = parsedFormula.variables();
  
        // Determine valid variables
        let validVariables = [...selectedStocks, 'cumulativeValue'];
        if (volumeWeightedPrice) {
          selectedStocks.forEach((stock) => {
            validVariables.push(`${stock}_VWAP`);
          });
        }
  
        const invalidVariables = variables.filter((v) => !validVariables.includes(v));
        if (invalidVariables.length > 0) {
          throw new Error(`Invalid variables used: ${invalidVariables.join(', ')}`);
        }
  
        parsedFormulas.push({
          id: formulaObj.id,
          name: name,
          formula: trimmedFormula,
          parsedFormula: parsedFormula,
        });
      } catch (error) {
        errors.push(`Formula "${name}": ${error.message}`);
      }
    });
  
    return { parsedFormulas, errors };
  };
  

  const calculateStockVolatility = useCallback((stock) => {
    const returns = stockDataManager.calculateReturns(priceData, stock);
    const volatility = stockDataManager.calculateVolatility(returns);
    return volatility;
  }, [priceData]);
  
  
 
  const mergeDataArrays = (dataArrays) => {
    const mergedData = {};
  
    dataArrays.forEach((array) => {
      array.forEach((dataPoint) => {
        const date = dataPoint.DATE;
        if (!mergedData[date]) {
          mergedData[date] = { DATE: date };
        }
        Object.keys(dataPoint).forEach((key) => {
          if (key !== 'DATE') {
            mergedData[date][key] = dataPoint[key];
          }
        });
      });
    });
  
    return Object.values(mergedData).sort((a, b) => new Date(a.DATE) - new Date(b.DATE));
  };
  

  


  const calculateVWAP = (data) => {
    const stocks = selectedStocks;
    let cumulativePV = {};
    let cumulativeVolume = {};
  
    return data.map((row) => {
      const updatedRow = { ...row };
      stocks.forEach((stock) => {
        const price = row[stock];
        const volume = row[`${stock}_Volume`];
  
        if (price !== null && price !== undefined && volume !== null && volume !== undefined) {
          cumulativePV[stock] = (cumulativePV[stock] || 0) + price * volume;
          cumulativeVolume[stock] = (cumulativeVolume[stock] || 0) + volume;
  
          updatedRow[`${stock}_VWAP`] = cumulativePV[stock] / cumulativeVolume[stock];
        } else {
          updatedRow[`${stock}_VWAP`] = null;
        }
      });
      return updatedRow;
    });
  };
  
  
  const fetchData = useCallback(async () => {
    if (selectedStocks.length > 0 && fullDateRange) {
      try {
        const { minDate, maxDate } = calculateDateRange();
  
        // Fetch price and volume data for selected stocks
        const fetchedData = stockDataManager.getStockData(selectedStocks, minDate, maxDate);
  
        // Calculate cumulative data
        const cumulativeData = calculateCumulativeValue(fetchedData);
  
        // Calculate VWAP if enabled
        let dataWithVWAP;
        if (volumeWeightedPrice) {
          dataWithVWAP = calculateVWAP(cumulativeData);
        } else {
          dataWithVWAP = cumulativeData;
        }
  
        // Apply data transformations
        let transformedData = applyDataTransformations(dataWithVWAP);
  
        // Calculate moving averages
        let processedData = calculateMovingAverages(transformedData);
  
        // Calculate technical indicators
        if (technicalIndicators.includes('RSI')) {
          processedData = calculateRSI(processedData);
        }
        if (technicalIndicators.includes('MACD')) {
          processedData = calculateMACD(processedData);
        }
  
        // Apply custom formulas
        if (parsedCustomFormulas.length > 0) {
          const updatedData = processedData.map((row) => {
            const newRow = { ...row };
            parsedCustomFormulas.forEach(({ parsedFormula, id, name }) => {
              try {
                // Prepare the scope with available variables
                const scope = { ...row };
  
                // Evaluate the formula
                const result = parsedFormula.evaluate(scope);
  
                // Add the result to the row with a key like `CustomFormula_<name>`
                newRow[`CustomFormula_${name}`] = result;
              } catch (error) {
                // Handle evaluation errors if necessary
                console.error(`Error evaluating custom formula "${name}":`, error);
                newRow[`CustomFormula_${name}`] = null;
              }
            });
            return newRow;
          });
  
          // Update the data with the custom formula values
          processedData = updatedData;
        }
  
        // Set the processed data
        setPriceData(processedData);
        setCumulativeData(processedData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
        alert('An error occurred while fetching data. Please try again.');
      }
    }
  }, [
    selectedStocks,
    weights,
    fullDateRange,
    calculateDateRange,
    movingAveragePeriods,
    technicalIndicators,
    dataTransformations,
    parsedCustomFormulas,
    volumeWeightedPrice,
  ]);
  
  const calculateRiskDistribution = useCallback(() => {
    const riskLevels = { 'Low Risk': 0, 'Medium Risk': 0, 'High Risk': 0 };
  
    selectedStocks.forEach(stock => {
      const volatility = calculateStockVolatility(stock);
  
      if (volatility < 0.2) {
        riskLevels['Low Risk'] += 1;
      } else if (volatility < 0.5) {
        riskLevels['Medium Risk'] += 1;
      } else {
        riskLevels['High Risk'] += 1;
      }
    });
  
    return Object.keys(riskLevels).map(key => ({
      name: key,
      value: riskLevels[key],
    }));
  }, [selectedStocks, calculateStockVolatility]);
  

  const renderRiskProfileChart = useCallback(() => {
    const riskDistribution = calculateRiskDistribution();
    const colors = getColorScheme('vibrant');
  
    return (
      <PieChart>
        <Pie
          data={riskDistribution}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={80}
          label
        >
          {riskDistribution.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Legend />
        <Tooltip />
      </PieChart>
    );
  }, [calculateRiskDistribution]);
  
  
  const calculateMovingAverages = (data) => {
    const periods = movingAveragePeriods;
    const stocks = selectedStocks;
  
    return data.map((row, index, array) => {
      const updatedRow = { ...row };
      stocks.forEach((stock) => {
        periods.forEach((period) => {
          if (index >= period - 1) {
            const sum = array.slice(index - period + 1, index + 1)
              .reduce((acc, curr) => acc + (curr[stock] || 0), 0);
            updatedRow[`${stock}_MA${period}`] = sum / period;
          } else {
            updatedRow[`${stock}_MA${period}`] = null;
          }
        });
      });
      return updatedRow;
    });
  };
  
  

  const calculateRSI = (data, period = 14) => {
    const stocks = selectedStocks;
  
    let prevAvgGain = {};
    let prevAvgLoss = {};
  
    return data.map((row, index, array) => {
      const updatedRow = { ...row };
      stocks.forEach(stock => {
        if (index === 0) {
          updatedRow[`${stock}_RSI`] = null;
          prevAvgGain[stock] = 0;
          prevAvgLoss[stock] = 0;
        } else {
          const change = (row[stock] || 0) - (array[index - 1][stock] || 0);
          const gain = Math.max(change, 0);
          const loss = Math.max(-change, 0);
  
          if (index < period) {
            prevAvgGain[stock] += gain;
            prevAvgLoss[stock] += loss;
            updatedRow[`${stock}_RSI`] = null;
          } else if (index === period) {
            prevAvgGain[stock] = (prevAvgGain[stock] + gain) / period;
            prevAvgLoss[stock] = (prevAvgLoss[stock] + loss) / period;
  
            const rs = prevAvgGain[stock] / prevAvgLoss[stock];
            updatedRow[`${stock}_RSI`] = 100 - (100 / (1 + rs));
          } else {
            prevAvgGain[stock] = ((prevAvgGain[stock] * (period - 1)) + gain) / period;
            prevAvgLoss[stock] = ((prevAvgLoss[stock] * (period - 1)) + loss) / period;
  
            const rs = prevAvgGain[stock] / prevAvgLoss[stock];
            updatedRow[`${stock}_RSI`] = 100 - (100 / (1 + rs));
          }
        }
      });
      return updatedRow;
    });
  };

  
  const calculateMACD = (data, shortPeriod = 12, longPeriod = 26, signalPeriod = 9) => {
    const stocks = selectedStocks;
  
    // Initialize EMA arrays
    let emaShort = {};
    let emaLong = {};
    let signalLine = {};
  
    return data.map((row, index, array) => {
      const updatedRow = { ...row };
      stocks.forEach(stock => {
        const price = row[stock] || 0;
  
        // Calculate EMA Short
        if (index === 0) {
          emaShort[stock] = price;
          emaLong[stock] = price;
          signalLine[stock] = 0;
        } else {
          const kShort = 2 / (shortPeriod + 1);
          const kLong = 2 / (longPeriod + 1);
          emaShort[stock] = price * kShort + emaShort[stock] * (1 - kShort);
          emaLong[stock] = price * kLong + emaLong[stock] * (1 - kLong);
        }
  
        // Calculate MACD Line
        const macdLine = emaShort[stock] - emaLong[stock];
  
        // Calculate Signal Line
        if (index < longPeriod + signalPeriod) {
          signalLine[stock] = signalLine[stock] + macdLine;
          updatedRow[`${stock}_MACD`] = null;
          updatedRow[`${stock}_MACDSignal`] = null;
          updatedRow[`${stock}_MACDHistogram`] = null;
        } else if (index === longPeriod + signalPeriod) {
          signalLine[stock] = signalLine[stock] / signalPeriod;
          updatedRow[`${stock}_MACD`] = macdLine;
          updatedRow[`${stock}_MACDSignal`] = signalLine[stock];
          updatedRow[`${stock}_MACDHistogram`] = macdLine - signalLine[stock];
        } else {
          const kSignal = 2 / (signalPeriod + 1);
          signalLine[stock] = macdLine * kSignal + signalLine[stock] * (1 - kSignal);
  
          updatedRow[`${stock}_MACD`] = macdLine;
          updatedRow[`${stock}_MACDSignal`] = signalLine[stock];
          updatedRow[`${stock}_MACDHistogram`] = macdLine - signalLine[stock];
        }
      });
      return updatedRow;
    });
  };
  

  useEffect(() => {
    fetchData();
  }, [fetchData, timeFilter, weights, dataTransformations, parsedCustomFormulas, volumeWeightedPrice]);

  const filteredStocks = useMemo(() => {
    // Step 1: Filter by Liquidity/Market Cap
    const filtered = stockOptions.filter(stock => {
      const adtv = stockDataManager.calculateADTV(stock, 30);
      const lastPrice = priceData[priceData.length - 1]?.[stock];
      const marketCap = lastPrice * 1000000; // Simplified market cap calculation
      return adtv >= liquidityThreshold && marketCap >= marketCapThreshold;
    });

    // Step 2: Recalculate top 100 most liquid
    const sortedByLiquidity = filtered.sort((a, b) => 
      stockDataManager.calculateADTV(b, 30) - stockDataManager.calculateADTV(a, 30)
    ).slice(0, 100);

    // Step 3: Rank by momentum
    const rankedByMomentum = sortedByLiquidity.sort((a, b) => {
      const momentumA = calculateMomentum(a, momentumPeriod);
      const momentumB = calculateMomentum(b, momentumPeriod);
      return momentumB - momentumA;
    });

    // Step 4: Rank by Adv/mean
    return rankedByMomentum.sort((a, b) => {
      const advMeanA = calculateAdvMean(a);
      const advMeanB = calculateAdvMean(b);
      return advMeanB - advMeanA;
    });
  }, [stockOptions, liquidityThreshold, marketCapThreshold, momentumPeriod, priceData]);

 

  const calculateMomentum = useCallback((stock, period) => {
    const prices = priceData.map(d => d[stock]).filter(Boolean);
    if (prices.length < period) return 0;
    return (prices[prices.length - 1] / prices[prices.length - period] - 1) * 100;
  }, [priceData]);



  const calculateAverageVolatility = useCallback(() => {
    const volatilities = selectedStocks.map(stock => calculateStockVolatility(stock));
    const average = volatilities.reduce((sum, vol) => sum + vol, 0) / volatilities.length;
    return average;
  }, [selectedStocks, calculateStockVolatility]);
  


  const calculateAdvMean = useCallback((stock) => {
    const prices = priceData.map(d => d[stock]).filter(Boolean);
    const mean = prices.reduce((sum, price) => sum + price, 0) / prices.length;
    return stockDataManager.calculateADTV(stock, 30) / mean;
  }, [priceData]);

  const constructPortfolio = useMemo(() => {
    const portfolio = [];
  
    // Define volatility thresholds based on risk tolerance
    const riskThresholds = {
      low: { maxVolatility: 0.2 },
      medium: { minVolatility: 0.2, maxVolatility: 0.5 },
      high: { minVolatility: 0.5 },
    };
  
    // Filter stocks based on risk tolerance
    const filteredStocksByRisk = selectedStocks.filter((stock) => {
      const volatility = calculateStockVolatility(stock);
      const thresholds = riskThresholds[riskTolerance];
  
      if (thresholds.minVolatility && volatility < thresholds.minVolatility) {
        return false;
      }
      if (thresholds.maxVolatility && volatility > thresholds.maxVolatility) {
        return false;
      }
      return true;
    });
  
    // Adjust weights based on the filtered stocks
    const totalStocks = filteredStocksByRisk.length;
    filteredStocksByRisk.forEach(stock => {
      const weight = 1 / totalStocks;
      portfolio.push({
        stock,
        weight,
      });
    });
  
    return portfolio;
  }, [selectedStocks, riskTolerance, calculateStockVolatility]);

  

  const calculateVolatilityWeight = useCallback((stock) => {
    const returns = stockDataManager.calculateReturns(priceData, stock);
    const volatility = stockDataManager.calculateVolatility(returns);
    return 1 / volatility;
  }, [priceData]);

  const handleStockChange = useCallback((newSelectedStocks) => {
    const initialWeights = newSelectedStocks.reduce((acc, stock) => {
      acc[stock] = 1 / newSelectedStocks.length;
      return acc;
    }, {});
    setWeights(initialWeights);
    setSelectedStocks(newSelectedStocks);
  }, []);
  

  const handleWeightChange = useCallback((stock, value) => {
    setWeights(prev => ({ ...prev, [stock]: parseFloat(value) }));
  }, []);

  const toggleChartType = useCallback(() => {
    const types = ['line', 'area', 'bar', 'scatter', 'pie', 'composed', 'radar', 'treemap'];
    setChartType(prevType => types[(types.indexOf(prevType) + 1) % types.length]);
  }, []);

  const calculateCumulativeValue = useCallback((data) => {
    return data.map(day => {
      const cumulativeValue = selectedStocks.reduce((sum, stock) => {
        const weight = weights[stock] !== undefined ? weights[stock] : 1 / selectedStocks.length;
        return sum + (day[stock] || 0) * weight;
      }, 0);
      return { ...day, cumulativeValue };
    });
  }, [selectedStocks, weights]);
  

  const filterDataByTime = useCallback((data) => {
    const now = new Date();
    switch (timeFilter) {
      case '1d':
        return data.filter(d => new Date(d.DATE) >= new Date(now.setDate(now.getDate() - 1)));
      case '1w':
        return data.filter(d => new Date(d.DATE) >= new Date(now.setDate(now.getDate() - 7)));
      case '1m':
        return data.filter(d => new Date(d.DATE) >= new Date(now.setMonth(now.getMonth() - 1)));
      case '3m':
        return data.filter(d => new Date(d.DATE) >= new Date(now.setMonth(now.getMonth() - 3)));
      case '6m':
        return data.filter(d => new Date(d.DATE) >= new Date(now.setMonth(now.getMonth() - 6)));
      case '1y':
        return data.filter(d => new Date(d.DATE) >= new Date(now.setFullYear(now.getFullYear() - 1)));
      case '5y':
        return data.filter(d => new Date(d.DATE) >= new Date(now.setFullYear(now.getFullYear() - 5)));
      default:
        return data;
    }
  }, [timeFilter]);

  const renderCumulativeChart = useCallback(() => {
    return (
      <LineChart data={cumulativeData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="DATE" 
          tickFormatter={(date) => new Date(date).toLocaleDateString()} 
          angle={-45}
          textAnchor="end"
          height={70}
        />
        <YAxis />
        
        <Legend />
        <Line 
          type="monotone" 
          dataKey="cumulativeValue" 
          stroke="#8884d8" 
          fill="#8884d8"
          name="Cumulative Value"
          strokeWidth={2}
        />
        {comparisonStock && (
          <Line 
            type="monotone" 
            dataKey={comparisonStock} 
            stroke="#82ca9d" 
            fill="#82ca9d"
            name={comparisonStock}
            dot={false}
          />
        )}
      </LineChart>
    );
  }, [cumulativeData, comparisonStock]);

  const renderVWAPChart = useCallback(() => {
    if (!volumeWeightedPrice) return null;
  
    const colors = getColorScheme(colorScheme);
  
    return (
      <LineChart data={priceData} margin={{ top: 5, right: 50, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="DATE"
          tickFormatter={(date) => new Date(date).toLocaleDateString()}
          angle={-45}
          textAnchor="end"
          height={70}
        />
        <YAxis
          domain={['auto', 'auto']}
          allowDataOverflow={true}
        />
        <RechartsTooltip />
        <Legend />
        {/* Render VWAP lines for each selected stock */}
        {selectedStocks.map((stock, index) => (
          <Line
            key={`${stock}_VWAP`}
            type="monotone"
            dataKey={`${stock}_VWAP`}
            stroke={colors[index % colors.length]}
            name={`${stock} VWAP`}
            strokeWidth={2}
          />
        ))}
      </LineChart>
    );
  }, [priceData, selectedStocks, volumeWeightedPrice, colorScheme]);
  

  

  const renderChart = useCallback(() => {
    const chartData = chartType === 'bar' ? volumeData : priceData;
  
    // Prepare color scheme
    const colors = getColorScheme(colorScheme);
  
    const ChartComponent = {
      'line': LineChart,
      'area': AreaChart,
      'bar': BarChart,
      'scatter': ScatterChart,
      'pie': PieChart,
      'composed': ComposedChart
    }[chartType] || LineChart;
  
    const DataComponent = {
      'line': Line,
      'area': Area,
      'bar': Bar,
      'scatter': Scatter,
      'pie': Pie
    }[chartType] || Line;
  
    const customTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <Box bg={bgColor} p={3} borderRadius="md" boxShadow="md">
            <Text fontWeight="bold">{new Date(label).toLocaleDateString()}</Text>
            {payload.map((entry, index) => (
              <Text key={index} color={entry.color}>
                {entry.name}: {entry.value !== null && entry.value !== undefined ? entry.value.toFixed(2) : 'N/A'}
              </Text>
            ))}
          </Box>
        );
      }
      return null;
    };
  
    // Determine data keys for regular price data
    const dataKeys = selectedStocks.map((stock) => ({
      key: stock,
      name: stock,
      stock,
    }));
  
    return (
      <ChartComponent data={chartData} margin={{ top: 5, right: 50, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="DATE"
          tickFormatter={(date) => new Date(date).toLocaleDateString()}
          angle={-45}
          textAnchor="end"
          height={70}
        />
        <YAxis
          yAxisId="left"
          scale={dataTransformations.includes('log') ? 'log' : 'auto'}
          domain={['auto', 'auto']}
          allowDataOverflow={true}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          scale={dataTransformations.includes('log') ? 'log' : 'auto'}
          domain={['auto', 'auto']}
          allowDataOverflow={true}
        />
  
        <RechartsTooltip content={customTooltip} />
        <Legend />
  
        {/* Render lines for each selected stock using regular price data */}
        {dataKeys.map(({ key, name }, index) => (
          <DataComponent
            key={key}
            yAxisId="left"
            type="monotone"
            dataKey={key}
            stroke={colors[index % colors.length]}
            name={name}
            strokeWidth={2}
          />
        ))}
  
        {/* Render custom formulas */}
        {parsedCustomFormulas.map(({ name }, index) => (
          <DataComponent
            key={`CustomFormula_${name}`}
            yAxisId="left"
            type="monotone"
            dataKey={`CustomFormula_${name}`}
            stroke={colors[(selectedStocks.length + index) % colors.length]}
            name={`Custom Formula: ${name}`}
            strokeWidth={2}
            strokeDasharray="5 5"
          />
        ))}
  
        {/* Render moving averages */}
        {movingAveragePeriods.map((period) =>
          dataKeys.map(({ stock }, index) => (
            <Line
              key={`${stock}_MA${period}`}
              yAxisId="left"
              type="monotone"
              dataKey={`${stock}_MA${period}`}
              stroke={colors[(index + movingAveragePeriods.length) % colors.length]}
              dot={false}
              name={`${stock} MA${period}`}
              strokeDasharray="5 5"
            />
          ))
        )}
  
        {/* Render technical indicators */}
        {technicalIndicators.includes('RSI') &&
          dataKeys.map(({ stock }, index) => (
            <Line
              key={`${stock}_RSI`}
              yAxisId="right"
              type="monotone"
              dataKey={`${stock}_RSI`}
              stroke={colors[(index + 2 * movingAveragePeriods.length) % colors.length]}
              dot={false}
              name={`${stock} RSI`}
            />
          ))}
  
        {/* Additional code for MACD and other indicators if needed */}
      </ChartComponent>
    );
  }, [
    chartType,
    priceData,
    volumeData,
    selectedStocks,
    movingAveragePeriods,
    technicalIndicators,
    showTrendlines,
    bgColor,
    colorScheme,
    dataTransformations,
  ]);
  


  const renderPortfolioSummary = useCallback(() => {
    const totalValue = portfolioValue;
    const numStocks = selectedStocks.length;
    const averageVolatility = calculateAverageVolatility();
    const formattedVolatility = (averageVolatility * 100).toFixed(2);
  
    return (
      <Box borderWidth="1px" borderRadius="md" p={4} mb={4}>
        <Heading as="h3" size="md" mb={4}>
          Portfolio Summary
        </Heading>
        <VStack align="start">
          <Text>Total Portfolio Value: ${totalValue.toLocaleString()}</Text>
          <Text>Number of Stocks: {numStocks}</Text>
          <Text>Average Volatility: {formattedVolatility}%</Text>
          <Text>Risk Tolerance: {riskTolerance.charAt(0).toUpperCase() + riskTolerance.slice(1)}</Text>
          <Text>
            Rebalancing Frequency: {rebalancingFrequency.charAt(0).toUpperCase() + rebalancingFrequency.slice(1)}
          </Text>
        </VStack>
      </Box>
    );
  }, [portfolioValue, selectedStocks, riskTolerance, rebalancingFrequency, calculateAverageVolatility]);
  


  const renderSecondaryChart = useCallback(() => {
    // This function is no longer needed in the redesigned version
    // You can either remove all references to it or implement a placeholder
    return null;
  }, []);

  const renderFundamentalData = () => {
    if (fundamentalFactors.length === 0) return null;
  
    return (
      <Box borderWidth="1px" borderRadius="md" p={4} mb={4}>
        <Heading as="h3" size="md" mb={4}>
          Fundamental Factors
        </Heading>
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          {selectedStocks.map((stock) => {
            const data = fundamentalData[stock];
            return (
              <Box key={stock} p={3} borderWidth="1px" borderRadius="md">
                <Text fontWeight="bold" mb={2}>{stock}</Text>
                {data ? (
                  <VStack align="start" spacing={1}>
                    {fundamentalFactors.includes('PE') && (
                      <Text>P/E Ratio: {parseFloat(data.PE).toFixed(2)}</Text>
                    )}
                    {fundamentalFactors.includes('PB') && (
                      <Text>P/B Ratio: {parseFloat(data.PB).toFixed(2)}</Text>
                    )}
                    {fundamentalFactors.includes('DividendYield') && (
                      <Text>
                        Dividend Yield: {(parseFloat(data.DividendYield) * 100).toFixed(2)}%
                      </Text>
                    )}
                    {fundamentalFactors.includes('EPS') && (
                      <Text>
                        EPS Growth: {(parseFloat(data.EPSGrowth) * 100).toFixed(2)}%
                      </Text>
                    )}
                  </VStack>
                ) : (
                  <Text>No data available</Text>
                )}
              </Box>
            );
          })}
        </Grid>
      </Box>
    );
  };
  


  const renderControls = () => (
    <VStack spacing={4} align="stretch">
      <FormControl>
        <FormLabel>Select Stocks</FormLabel>
        <Box height="200px" overflowY="auto" borderWidth={1} borderRadius="md" p={2}>
          <CheckboxGroup colorScheme="blue" value={selectedStocks} onChange={handleStockChange}>
            <VStack align="start" spacing={2}>
              {stockOptions.map((stock) => (
                <Checkbox key={stock} value={stock}>{stock}</Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>
        </Box>
      </FormControl>
      {selectedStocks.map(stock => (
        <HStack key={stock}>
          <Text width="100px">{stock}</Text>
          <NumberInput
            value={weights[stock]}
            onChange={(_, value) => handleWeightChange(stock, value)}
            step={0.01}
            min={0}
            max={1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </HStack>
      ))}
      <FormControl>
        <FormLabel>Chart Type</FormLabel>
        <Menu>
          <MenuButton as={Button} rightIcon={<IoMdSettings />}>
            {chartType.charAt(0).toUpperCase() + chartType.slice(1)}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => setChartType('line')} icon={<FaChartLine />}>Line</MenuItem>
            <MenuItem onClick={() => setChartType('area')} icon={<FaChartArea />}>Area</MenuItem>
            <MenuItem onClick={() => setChartType('bar')} icon={<FaChartBar />}>Bar</MenuItem>
            <MenuItem onClick={() => setChartType('scatter')} icon={<FaDotCircle />}>Scatter</MenuItem>
            <MenuItem onClick={() => setChartType('pie')} icon={<FaChartPie />}>Pie</MenuItem>
            <MenuItem onClick={() => setChartType('treemap')} icon={<FaTree />}>Treemap</MenuItem>
          </MenuList>
        </Menu>
      </FormControl>
      <FormControl>
        <FormLabel>Time Filter</FormLabel>
        <Select value={timeFilter} onChange={(e) => setTimeFilter(e.target.value)}>
          <option value="all">All Time</option>
          <option value="1d">Last Day</option>
          <option value="1w">Last Week</option>
          <option value="1m">Last Month</option>
          <option value="3m">Last 3 Months</option>
          <option value="6m">Last 6 Months</option>
          <option value="1y">Last Year</option>
          <option value="5y">Last 5 Years</option>
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>Liquidity Threshold</FormLabel>
        <Slider
          value={liquidityThreshold}
          onChange={(value) => setLiquidityThreshold(value)}
          min={0}
          max={10000000}
          step={100000}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb boxSize={6}>
            <Box color="blue.500" as={FaChartLine} />
          </SliderThumb>
        </Slider>
        <Text mt={2}>{liquidityThreshold.toLocaleString()}</Text>
      </FormControl>
      <FormControl>
        <FormLabel>Market Cap Threshold</FormLabel>
        <Slider
          value={marketCapThreshold}
          onChange={(value) => setMarketCapThreshold(value)}
          min={0}
          max={1000000000000}
          step={1000000000}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb boxSize={6}>
            <Box color="green.500" as={FaChartBar} />
          </SliderThumb>
        </Slider>
        <Text mt={2}>{marketCapThreshold.toLocaleString()}</Text>
      </FormControl>
      <FormControl>
        <FormLabel>Momentum Period</FormLabel>
        <NumberInput value={momentumPeriod} onChange={(_, value) => setMomentumPeriod(value)} min={1} max={100}>
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
      <FormControl>
        <FormLabel>Portfolio Size</FormLabel>
        <NumberInput value={portfolioSize} onChange={(_, value) => setPortfolioSize(value)} min={1} max={100}>
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
      <FormControl>
        <FormLabel>Portfolio Value</FormLabel>
        <NumberInput
          value={portfolioValue}
          onChange={(_, value) => setPortfolioValue(value)}
          min={1000}
          max={1000000000}
          step={1000}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
      <FormControl>
        <FormLabel>Weighting Method</FormLabel>
        <RadioGroup value={weightingMethod} onChange={setWeightingMethod}>
          <Stack direction="row">
            <Radio value="equal">Equal</Radio>
            <Radio value="volatility">Volatility</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Show Mean Value</FormLabel>
        <Switch isChecked={showMeanValue} onChange={(e) => setShowMeanValue(e.target.checked)} />
      </FormControl>
      {chartType !== 'pie' && (
        <FormControl>
          <FormLabel>Comparison Stock</FormLabel>
          <Select 
            placeholder="Select stock to compare" 
            value={comparisonStock} 
            onChange={(e) => setComparisonStock(e.target.value)}
          >
            {selectedStocks.map(stock => (
              <option key={stock} value={stock}>{stock}</option>
            ))}
          </Select>
        </FormControl>
      )}
      <FormControl>
        <FormLabel>Show Trendlines</FormLabel>
        <Switch isChecked={showTrendlines} onChange={(e) => setShowTrendlines(e.target.checked)} />
      </FormControl>
      <FormControl>
      <FormLabel>Moving Average Periods</FormLabel>
      <HStack>
        {movingAveragePeriods.map((period, index) => (
          <NumberInput
            key={index}
            value={period}
            onChange={(_, value) => {
              const newPeriods = [...movingAveragePeriods];
              newPeriods[index] = value;
              setMovingAveragePeriods(newPeriods);
            }}
            min={1}
            max={200}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        ))}
      </HStack>
    </FormControl>

    </VStack>
  );

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box maxW="1200px" mx="auto" py={8} px={isMobile ? 4 : 0}>
      <Heading as="h1" size={isMobile ? "lg" : "xl"} mb={6}>
        Advanced Data Visualization
      </Heading>

      {/* Move the error display code here */}
      {customFormulaErrors.length > 0 && (
        <Box bg="red.100" borderRadius="md" p={4} mb={4}>
          <Heading as="h3" size="md" mb={2}>Formula Errors</Heading>
          <VStack align="start">
            {customFormulaErrors.map((error, index) => (
              <Text key={index} color="red.800">{error}</Text>
            ))}
          </VStack>
        </Box>
      )}

      <Grid templateColumns={isMobile ? "1fr" : "repeat(3, 1fr)"} gap={6}>
        <GridItem colSpan={1}>
          <VStack spacing={4} align="stretch">
            <Button leftIcon={<IoMdSettings />} onClick={onOpen} colorScheme="blue">
              Open Settings
            </Button>
            {renderControls()}
          </VStack>
        </GridItem>
        <GridItem colSpan={isMobile ? 1 : 2}>
        {renderFundamentalData()}
        <Box height={isMobile ? "300px" : "400px"} mb={8}>
          <ResponsiveContainer width="100%" height="100%">
            {renderCumulativeChart()}
          </ResponsiveContainer>
        </Box>
        <Box height={isMobile ? "300px" : "400px"} mb={8}>
          <ResponsiveContainer width="100%" height="100%">
            {renderChart()}
          </ResponsiveContainer>
        </Box>
        {volumeWeightedPrice && (
          <Box height={isMobile ? "200px" : "300px"} mb={8}>
            <ResponsiveContainer width="100%" height="100%">
              {renderVWAPChart()}
            </ResponsiveContainer>
          </Box>
        )}
       
        <Box borderWidth="1px" borderRadius="md" p={4} mb={8}>
          <Heading as="h3" size="md" mb={4}>
            Portfolio Risk Profile
          </Heading>
          <Flex>
            <ResponsiveContainer width="50%" height={240}>
              {renderRiskProfileChart()}
            </ResponsiveContainer>
            <ResponsiveContainer width="50%" height={240}>
              {renderPortfolioSummary()}
            </ResponsiveContainer>
          </Flex>
        </Box>
        <Box height={isMobile ? "300px" : "400px"}>
          <ResponsiveContainer width="100%" height="100%">
            {renderSecondaryChart()}
          </ResponsiveContainer>
        </Box>
      </GridItem>
      </Grid>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Advanced Settings</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <FormControl>
                <FormLabel>Color Scheme</FormLabel>
                <Select value={colorScheme} onChange={(e) => setColorScheme(e.target.value)}>
                  <option value="default">Default</option>
                  <option value="monochrome">Monochrome</option>
                  <option value="pastel">Pastel</option>
                  <option value="vibrant">Vibrant</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Data Update Interval (ms)</FormLabel>
                <NumberInput
                  value={dataUpdateInterval}
                  onChange={(_, value) => setDataUpdateInterval(value)}
                  min={1000}
                  max={300000}
                  step={1000}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <FormControl>
                <FormLabel>Show Outliers</FormLabel>
                <Switch isChecked={showOutliers} onChange={(e) => setShowOutliers(e.target.checked)} />
              </FormControl>
              <FormControl>
                <FormLabel>Correlation Threshold</FormLabel>
                <Slider
                  value={correlationThreshold}
                  onChange={(value) => setCorrelationThreshold(value)}
                  min={0}
                  max={1}
                  step={0.01}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb boxSize={6}>
                    <Box color="teal.500" as={FaChartLine} />
                  </SliderThumb>
                </Slider>
                <Text mt={2}>{correlationThreshold.toFixed(2)}</Text>
              </FormControl>
              <FormControl>
                <FormLabel>Risk Tolerance</FormLabel>
                <RadioGroup value={riskTolerance} onChange={setRiskTolerance}>
                  <Stack direction="row">
                    <Radio value="low">Low</Radio>
                    <Radio value="medium">Medium</Radio>
                    <Radio value="high">High</Radio>
                  </Stack>
                </RadioGroup>
                <FormHelperText>
                  Adjusts the portfolio composition to match your risk preference.
                  Low risk focuses on stable stocks, while high risk includes more volatile stocks with higher growth potential.
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Rebalancing Frequency</FormLabel>
                <Select value={rebalancingFrequency} onChange={(e) => setRebalancingFrequency(e.target.value)}>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="quarterly">Quarterly</option>
                  <option value="annually">Annually</option>
                </Select>
                <FormHelperText>
                  Determines how often your portfolio allocations are adjusted to maintain your target weights.
                  More frequent rebalancing keeps your portfolio aligned but may incur higher transaction costs.
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Benchmark Index</FormLabel>
                <Select value={benchmarkIndex} onChange={(e) => setBenchmarkIndex(e.target.value)}>
                  <option value="">None</option>
                  <option value="SP500">S&P 500</option>
                  <option value="DJIA">Dow Jones Industrial Average</option>
                  <option value="NASDAQ">NASDAQ Composite</option>
                </Select>
              </FormControl>   
              <FormControl>
                <FormLabel>Volume Weighted Price</FormLabel>
                <Switch
                  isChecked={volumeWeightedPrice}
                  onChange={(e) => setVolumeWeightedPrice(e.target.checked)}
                />
                <FormHelperText>
                  Enable to display the Volume Weighted Average Price (VWAP) instead of regular prices. VWAP provides the average price weighted by volume, offering a more accurate reflection of a stock's trading activity. Note: Enabling this may impact performance due to additional calculations.
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Technical Indicators</FormLabel>
                <CheckboxGroup colorScheme="blue" value={technicalIndicators} onChange={setTechnicalIndicators}>
                  <VStack align="start">
                    <Checkbox value="RSI">RSI</Checkbox>
                    <Checkbox value="MACD">MACD</Checkbox>
                  </VStack>
                </CheckboxGroup>
              </FormControl>

              <FormControl>
                <FormLabel>Fundamental Factors</FormLabel>
                <CheckboxGroup colorScheme="green" value={fundamentalFactors} onChange={setFundamentalFactors}>
                  <VStack align="start">
                    <Checkbox value="PE">P/E Ratio</Checkbox>
                    <Checkbox value="PB">P/B Ratio</Checkbox>
                    <Checkbox value="DividendYield">Dividend Yield</Checkbox>
                    <Checkbox value="EPS">EPS Growth</Checkbox>
                  </VStack>
                </CheckboxGroup>
              </FormControl>
              <FormControl>
              <FormLabel>Custom Formulas</FormLabel>

              {/* Dropdown to select existing formulas */}
              <Select
                placeholder="Select a formula"
                value={selectedFormulaId || ''}
                onChange={(e) => setSelectedFormulaId(e.target.value)}
              >
                {customFormulas.map((formula) => (
                  <option key={formula.id} value={formula.id}>
                    {formula.name}
                  </option>
                ))}
              </Select>

              {/* Button to create a new formula */}
              <Button mt={2} onClick={handleAddNewFormula} colorScheme="blue">
                New Formula
              </Button>

              {selectedFormulaId && (
                <>
                  {/* Input for formula name */}
                  <FormLabel mt={4}>Formula Name</FormLabel>
                  <Input
                    value={getSelectedFormula()?.name || ''}
                    onChange={handleFormulaNameChange}
                    placeholder="Enter formula name"
                  />

                  {/* Textarea for formula content */}
                  <FormLabel mt={4}>Formula</FormLabel>
                  <Textarea
                    value={getSelectedFormula()?.formula || ''}
                    onChange={handleFormulaContentChange}
                    placeholder="Enter the formula. Use stock symbols as variables."
                  />

                  {/* Buttons to apply or delete the formula */}
                  <HStack mt={4} spacing={4}>
                    <Button colorScheme="green" onClick={handleApplyFormula}>
                      Apply
                    </Button>
                    <Button colorScheme="red" onClick={handleDeleteFormula}>
                      Delete
                    </Button>
                  </HStack>
                </>
              )}

              <FormHelperText>
                Use stock symbols and existing data fields as variables. Supported operations: +, -, *, /, ^, etc.
              </FormHelperText>
            </FormControl>

              <FormControl>
                <FormLabel>Data Transformations</FormLabel>
                <CheckboxGroup colorScheme="purple" value={dataTransformations} onChange={setDataTransformations}>
                  <VStack align="start">
                    <Checkbox value="log">Logarithmic</Checkbox>
                    <Checkbox value="percent">Percentage Change</Checkbox>
                    <Checkbox value="normalized">Normalized</Checkbox>
                  </VStack>
                </CheckboxGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Annotation Mode</FormLabel>
                <Switch isChecked={annotationMode} onChange={(e) => setAnnotationMode(e.target.checked)} />
              </FormControl>
              <FormControl>
                <FormLabel>Alert Thresholds</FormLabel>
                {selectedStocks.map(stock => (
                  <HStack key={stock}>
                    <Text>{stock}:</Text>
                    <NumberInput
                      value={alertThresholds[stock] || ''}
                      onChange={(_, value) => setAlertThresholds(prev => ({ ...prev, [stock]: value }))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </HStack>
                ))}
              </FormControl>
              <FormControl>
                <FormLabel>Time Zone</FormLabel>
                <Select value={selectedTimeZone} onChange={(e) => setSelectedTimeZone(e.target.value)}>
                  <option value="UTC">UTC</option>
                  <option value="America/New_York">Eastern Time</option>
                  <option value="America/Chicago">Central Time</option>
                  <option value="America/Denver">Mountain Time</option>
                  <option value="America/Los_Angeles">Pacific Time</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Currency Conversion</FormLabel>
                <Select value={currencyConversion} onChange={(e) => setCurrencyConversion(e.target.value)}>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                  <option value="JPY">JPY</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Dark Mode</FormLabel>
                <Switch isChecked={darkMode} onChange={(e) => setDarkMode(e.target.checked)} />
              </FormControl>
              <FormControl>
                <FormLabel>Auto Refresh</FormLabel>
                <Switch isChecked={autoRefresh} onChange={(e) => setAutoRefresh(e.target.checked)} />
              </FormControl>
              <FormControl>
                <FormLabel>Data Export Format</FormLabel>
                <RadioGroup value={dataExportFormat} onChange={setDataExportFormat}>
                  <Stack direction="row">
                    <Radio value="csv">CSV</Radio>
                    <Radio value="json">JSON</Radio>
                    <Radio value="xlsx">Excel</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Heatmap Metric</FormLabel>
                <Select value={heatmapMetric} onChange={(e) => setHeatmapMetric(e.target.value)}>
                  <option value="correlation">Correlation</option>
                  <option value="covariance">Covariance</option>
                  <option value="beta">Beta</option>
                </Select>
              </FormControl>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

// Utility functions
const getColorScheme = (scheme) => {
  switch (scheme) {
    case 'monochrome':
      return ['#000000', '#333333', '#666666', '#999999', '#CCCCCC'];
    case 'pastel':
      return ['#FFB3BA', '#FFDFBA', '#FFFFBA', '#BAFFC9', '#BAE1FF'];
    case 'vibrant':
      return ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8'];
    default:
      return ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#a4de6c'];
  }
};

const calculateMovingAverage = (data, period) => {
  return data.map((row, index, array) => {
    if (index < period - 1) return { ...row, MA: null };
    const sum = array.slice(index - period + 1, index + 1).reduce((acc, curr) => acc + curr.value, 0);
    return { ...row, MA: sum / period };
  });
};

const calculateBollingerBands = (data, period = 20, multiplier = 2) => {
  const sma = calculateMovingAverage(data, period);
  return sma.map((row, index, array) => {
    if (index < period - 1) return { ...row, upper: null, lower: null };
    const slice = array.slice(index - period + 1, index + 1);
    const std = Math.sqrt(slice.reduce((sum, item) => sum + Math.pow(item.value - item.MA, 2), 0) / period);
    return {
      ...row,
      upper: row.MA + multiplier * std,
      lower: row.MA - multiplier * std
    };
  });
};

const calculateRSI = (data, period = 14) => {
  let gains = 0;
  let losses = 0;
  return data.map((row, index, array) => {
    if (index < period) return { ...row, RSI: null };
    const change = row.value - array[index - 1].value;
    gains = (gains * (period - 1) + Math.max(change, 0)) / period;
    losses = (losses * (period - 1) + Math.max(-change, 0)) / period;
    const relativeStrength = gains / losses;
    return { ...row, RSI: 100 - (100 / (1 + relativeStrength)) };
  });
};

const calculateMACD = (data, shortPeriod = 12, longPeriod = 26, signalPeriod = 9) => {
  const emaShort = calculateEMA(data, shortPeriod);
  const emaLong = calculateEMA(data, longPeriod);
  const macdLine = emaShort.map((row, index) => ({
    ...row,
    MACD: row.EMA - emaLong[index].EMA
  }));
  const signalLine = calculateEMA(macdLine.map(row => ({ ...row, value: row.MACD })), signalPeriod);
  return signalLine.map((row, index) => ({
    ...row,
    MACDSignal: row.EMA,
    MACDHistogram: macdLine[index].MACD - row.EMA
  }));
};

const calculateEMA = (data, period) => {
  const k = 2 / (period + 1);
  let ema = data[0].value;
  return data.map((row) => {
    ema = row.value * k + ema * (1 - k);
    return { ...row, EMA: ema };
  });
};



export default DataVisualization;