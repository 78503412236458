import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, Button, useColorMode, Menu, MenuButton, MenuList, MenuItem, Avatar, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Input, VStack, useDisclosure } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const Header = () => {
  const { colorMode, toggleColorMode, setColorMode } = useColorMode();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    setColorMode('dark');
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user ?? null);
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [setColorMode]);

  const handleAuth = async (e) => {
    e.preventDefault();
    if (isLogin) {
      const { error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) console.error('Error logging in:', error.message);
    } else {
      const { error } = await supabase.auth.signUp({ email, password });
      if (error) console.error('Error signing up:', error.message);
    }
    onClose();
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.error('Error logging out:', error.message);
    else navigate('/');
  };

  return (
    <Box as="header" bg={colorMode === 'light' ? 'gray.100' : 'gray.900'} py={4} px={[3, 4]}>
      <Flex maxW="1200px" mx="auto" alignItems="center" justifyContent="space-between">
        <Heading as="h1" size={["sm", "lg"]}>
          <Link to="/">StockMaster</Link>
        </Heading>
        <Flex alignItems="center">
          {user ? (
            <>
              <Button as={Link} to="/subscription" colorScheme="blue" mr={[1, 2]} size={["xs", "md"]} fontSize={["xs", "md"]} px={[2, 4]}>
                Subscribe
              </Button>
              <Menu>
                <MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0}>
                  <Avatar size={'sm'} src={user.user_metadata.avatar_url} />
                </MenuButton>
                <MenuList>
                  <MenuItem as={Link} to="/profile">Profile</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </>
          ) : (
            <Button onClick={onOpen} colorScheme="green" mr={[1, 2]} size={["xs", "md"]} fontSize={["xs", "md"]} px={[2, 4]}>
              Login / Register
            </Button>
          )}
          <Button onClick={toggleColorMode} size={["xs", "md"]} fontSize={["xs", "md"]} px={[2, 4]}>
            {colorMode === 'light' ? 'Dark' : 'Light'}
          </Button>
        </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isLogin ? 'Login' : 'Register'}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleAuth}>
            <ModalBody>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Password</FormLabel>
                  <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" colorScheme="blue" mr={3}>
                {isLogin ? 'Login' : 'Register'}
              </Button>
              <Button variant="ghost" onClick={() => setIsLogin(!isLogin)}>
                {isLogin ? 'Need to Register?' : 'Already have an account?'}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Header;