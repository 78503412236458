import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex, Link as ChakraLink, useColorMode, IconButton, VStack } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link, useLocation } from 'react-router-dom';

const Navigation = () => {
  const { colorMode } = useColorMode();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef();

  const links = [
    { name: 'Home', path: '/' },
    { name: 'Portfolio Analysis', path: '/portfolio' },
    { name: 'Data Visualization', path: '/visualization' },
    { name: 'Backtesting', path: '/backtesting' },
    { name: 'Settings', path: '/settings' },
  ];

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <Box as="nav" bg={colorMode === 'light' ? 'gray.200' : 'gray.800'} py={2} ref={menuRef}>
      {isMobile ? (
        <Flex justifyContent="center" alignItems="center" flexDirection="column">
          <IconButton
            icon={<HamburgerIcon />}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Open menu"
            variant="ghost"
            size="lg"
          />
          {isMenuOpen && (
            <VStack
              mt={4}
              spacing={4}
              align="center"
              bg={colorMode === 'light' ? 'gray.100' : 'gray.700'}
              p={6}
              borderRadius="md"
              boxShadow="lg"
              width="90%"
              maxWidth="300px"
            >
              {links.map((link) => (
                <ChakraLink
                  as={Link}
                  key={link.path}
                  to={link.path}
                  fontWeight={location.pathname === link.path ? 'bold' : 'normal'}
                  color={location.pathname === link.path ? 'blue.500' : 'inherit'}
                  onClick={handleLinkClick}
                  fontSize="xl"
                  _hover={{ color: 'blue.500' }}
                >
                  {link.name}
                </ChakraLink>
              ))}
            </VStack>
          )}
        </Flex>
      ) : (
        <Flex maxW="1200px" mx="auto" justifyContent="center">
          {links.map((link) => (
            <ChakraLink
              as={Link}
              key={link.path}
              to={link.path}
              mx={2}
              fontWeight={location.pathname === link.path ? 'bold' : 'normal'}
              color={location.pathname === link.path ? 'blue.500' : 'inherit'}
            >
              {link.name}
            </ChakraLink>
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default Navigation;