import React, { useState } from 'react';
import { Box, Heading, Text, Button, SimpleGrid, Icon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure, useToast } from '@chakra-ui/react';
import { FaChartLine, FaSearchDollar, FaCog } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient'; // Make sure to import supabase client

const FeatureCard = ({ title, description, icon }) => (
  <Box borderWidth={1} borderRadius="lg" p={6} textAlign="center">
    <Icon as={icon} w={10} h={10} mb={4} />
    <Heading as="h3" size="md" mb={2}>
      {title}
    </Heading>
    <Text>{description}</Text>
  </Box>
);

const Home = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useState(null);
  const toast = useToast();

  const handleTryDemo = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    setUser(user);

    if (!user) {
      toast({
        title: 'Authentication required',
        description: 'Please log in to try the demo.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    onOpen();
  };

  return (
    <Box maxW="1200px" mx="auto" py={12}>
      <Box textAlign="center" mb={12}>
        <Heading as="h1" size="2xl" mb={4}>
          Welcome to StockMaster
        </Heading>
        <Text fontSize="xl" mb={6}>
          Empower your investment decisions with advanced portfolio analysis tools
        </Text>
        <Button onClick={handleTryDemo} colorScheme="blue" size="lg">
          Try Live Assistant
        </Button>
      </Box>

      <SimpleGrid columns={[1, null, 3]} spacing={8} mb={12}>
        <FeatureCard
          title="Portfolio Analysis"
          description="Gain insights into your portfolio's performance and risk metrics"
          icon={FaChartLine}
        />
        <FeatureCard
          title="Backtesting"
          description="Test your investment strategies using historical data"
          icon={FaSearchDollar}
        />
        <FeatureCard
          title="Customization"
          description="Tailor the platform to your specific needs and preferences"
          icon={FaCog}
        />
      </SimpleGrid>

      <Box textAlign="center">
        <Heading as="h2" size="xl" mb={4}>
          Ready to take your portfolio management to the next level?
        </Heading>
        <Button as={Link} to="/subscription" colorScheme="green" size="lg">
          Subscribe Now
        </Button>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="full" motionPreset="none">
        <ModalOverlay />
        <ModalContent margin={0} rounded={0} h="100vh">
          <ModalHeader borderBottomWidth={1} py={4}>
            Portfolio Master Assistant
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody p={0} h="calc(100vh - 60px)">
            <iframe
              src="https://ai-stockbroker.vercel.app/"
              title="Portfolio Master Assistant"
              width="100%"
              height="100%"
              style={{ border: 'none' }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Home;