import React from 'react';
import { Box, Flex, Text, Link, useColorMode, Stack, useBreakpointValue } from '@chakra-ui/react';

const Footer = () => {
  const { colorMode } = useColorMode();
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box as="footer" bg={colorMode === 'light' ? 'gray.100' : 'gray.900'} py={4}>
      <Flex
        maxW="1200px"
        mx="auto"
        direction={isMobile ? 'column' : 'row'}
        justifyContent={isMobile ? 'center' : 'space-between'}
        alignItems="center"
        textAlign={isMobile ? 'center' : 'left'}
      >
        <Text mb={isMobile ? 4 : 0}>&copy; 2024 StockMaster. All rights reserved.</Text>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 2 : 4}>
          <Link href="#">Privacy Policy</Link>
          <Link href="#">Terms of Service</Link>
          <Link href="#">Contact Us</Link>
        </Stack>
      </Flex>
    </Box>
  );
};

export default Footer;