import { readString } from 'react-papaparse';

class StockDataManager {
  constructor() {
    this.priceData = [];
    this.fundamentalData = {
      'AAPL': {
        PE: 30.5,
        PB: 15.2,
        DividendYield: 0.012,
        EPSGrowth: 0.08,
      },
      'GOOGL': {
        PE: 35.1,
        PB: 7.8,
        DividendYield: 0,
        EPSGrowth: 0.12,
      },
      // Add more stocks as needed
    };

    this.fundamentalData = this.getStockList().reduce((acc, stock) => {
      acc[stock] = {
        PE: Math.random() * 50,
        PB: Math.random() * 20,
        DividendYield: Math.random() * 0.05,
        EPSGrowth: Math.random() * 0.2,
      };
      return acc;
    }, {});
    
  }

  initializeFundamentalData() {
    const stocks = this.getStockList();
    this.fundamentalData = stocks.reduce((acc, stock) => {
      acc[stock] = {
        PE: (Math.random() * 40 + 10).toFixed(2), // Random P/E between 10 and 50
        PB: (Math.random() * 10 + 1).toFixed(2),  // Random P/B between 1 and 11
        DividendYield: (Math.random() * 0.05).toFixed(4), // Random yield between 0% and 5%
        EPSGrowth: (Math.random() * 0.2).toFixed(4), // Random EPS growth between 0% and 20%
      };
      return acc;
    }, {});
  }
  

  getFundamentalData(stock) {
    return this.fundamentalData[stock] || null;
  }
  
  async loadData() {
    try {
      const response = await fetch('prices.csv');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const rawData = await response.text();
      if (rawData.trim().startsWith('<!DOCTYPE html>')) {
        console.error('Received HTML instead of CSV. Check your server configuration.');
        return [];
      }
      this.priceData = this.parseCSV(rawData);
      console.log('Loaded price data:', this.priceData.length, 'rows');
      await this.initializeFundamentalData();
      return this.priceData;
    } catch (error) {
      console.error('Error loading CSV data:', error);
      return [];
    }
  }

  

  parseCSV(data) {
    try {
      const parsedData = readString(data, { header: true, skipEmptyLines: true }).data;
      return parsedData.map(row => {
        if (!row.DATE) {
          console.warn('Row missing DATE:', row);
          return null;
        }
        const date = this.parseDate(row.DATE);
        if (!date) {
          console.warn('Invalid date:', row.DATE);
          return null;
        }
        const parsedRow = { DATE: date };
  
        Object.entries(row).forEach(([key, value]) => {
          if (key === 'DATE') return;
          const floatValue = parseFloat(value);
          if (!isNaN(floatValue)) {
            parsedRow[key] = floatValue;
          } else {
            parsedRow[key] = 0;
          }
  
          // Generate mock volume data for each stock
          if (!key.endsWith('_Volume')) {
            parsedRow[`${key}_Volume`] = Math.floor(Math.random() * 1000000); // Random volume between 0 and 1,000,000
          }
        });
  
        return parsedRow;
      }).filter(row => row !== null);
    } catch (error) {
      console.error('Error parsing CSV data:', error);
      return [];
    }
  }
  




  parseDate(dateString) {
    if (!dateString) return null;
    const [year, month, day] = dateString.split('-').map(Number);
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      console.warn('Invalid date components:', dateString);
      return null;
    }
    return new Date(year, month - 1, day);
  }

  getStockData(stocks, startDate, endDate) {
    return this.priceData.filter(row => 
      row.DATE >= startDate && 
      row.DATE <= endDate
    ).map(row => {
      const newRow = { DATE: row.DATE };
      stocks.forEach(stock => {
        newRow[stock] = row[stock];
        newRow[`${stock}_Volume`] = row[`${stock}_Volume`];
      });
      return newRow;
    });
  }
  

  getStockList() {
    if (this.priceData.length === 0) return [];
    return Object.keys(this.priceData[0]).filter(key => key !== 'DATE');
  }

  getDateRange() {
    if (this.priceData.length === 0) return { minDate: new Date(), maxDate: new Date() };
    const dates = this.priceData.map(row => row.DATE);
    return {
      minDate: new Date(Math.min(...dates)),
      maxDate: new Date(Math.max(...dates))
    };
  }

  calculateReturns(data, stock) {
    const prices = data.map(d => d[stock]);
    return prices.slice(1).map((price, i) => (price - prices[i]) / prices[i]);
  }

  calculateVolatility(returns) {
    const meanReturn = returns.reduce((a, b) => a + b, 0) / returns.length;
    const squaredDiffs = returns.map(r => Math.pow(r - meanReturn, 2));
    return Math.sqrt(squaredDiffs.reduce((a, b) => a + b, 0) / (returns.length - 1)) * Math.sqrt(252);
  }

  calculateADTV(stock, days) {
    const endDate = new Date(this.priceData[this.priceData.length - 1].DATE);
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - days);
    const data = this.getStockData([stock], startDate, endDate); // Pass 'stock' as an array
    return data.reduce((sum, day) => sum + day[`${stock}_Volume`], 0) / days;
  }
  
  

  getVolumeData(stock, startDate, endDate) {
    return this.getStockData(stock, startDate, endDate).map(row => ({
      ...row,
      [stock]: Math.floor(Math.random() * 1000000) // Random volume between 0 and 1,000,000
    }));
  }
}

export const stockDataManager = new StockDataManager();