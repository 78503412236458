// src/components/Subscription.js
import React, { useState, useEffect } from 'react';
import { Box, Heading, SimpleGrid, VStack, Text, Button, List, ListItem, ListIcon, useColorMode, useBreakpointValue, useToast, Spinner } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { supabase } from '../supabaseClient';

const PricingCard = ({ title, price, features, isPopular, stripeProductId, onSubscribe }) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      borderWidth={1}
      borderRadius="lg"
      p={6}
      textAlign="center"
      position="relative"
      bg={isPopular ? (colorMode === 'light' ? 'blue.50' : 'blue.900') : (colorMode === 'light' ? 'white' : 'gray.700')}
      color={colorMode === 'light' ? 'gray.800' : 'white'}
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {isPopular && (
        <Text
          position="absolute"
          top={2}
          right={2}
          bg="blue.500"
          color="white"
          px={2}
          py={1}
          borderRadius="md"
          fontSize="sm"
        >
          Most Popular
        </Text>
      )}
      <Box>
        <Heading as="h3" size="lg" mb={2}>
          {title}
        </Heading>
        <Text fontSize="3xl" fontWeight="bold" mb={4}>
          ${price}
          <Text as="span" fontSize="sm" fontWeight="normal">
            /month
          </Text>
        </Text>
        <List spacing={3} textAlign="left" mb={6}>
          {features.map((feature, index) => (
            <ListItem key={index}>
              <ListIcon as={CheckCircleIcon} color={colorMode === 'light' ? 'green.500' : 'green.300'} />
              {feature}
            </ListItem>
          ))}
        </List>
      </Box>
      <Button 
        colorScheme={isPopular ? 'blue' : 'gray'} 
        size="lg" 
        width="full" 
        mt="auto"
        onClick={() => onSubscribe(stripeProductId)}
      >
        Subscribe Now
      </Button>
    </Box>
  );
};

const Subscription = () => {
  const { colorMode } = useColorMode();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const toast = useToast();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user ?? null);
    });

    fetchPlans();

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const fetchPlans = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('products')
        .select('id, name, price, description, metadata, stripe_price_id')
        .eq('active', true);
      if (error) throw error;
      console.log('Fetched plans:', data);
      setPlans(data);
    } catch (error) {
      console.error('Error fetching plans:', error);
      toast({
        title: 'Error fetching plans',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubscribe = async (stripeProductId) => {
    console.log('handleSubscribe called with:', stripeProductId);
    
    if (!user) {
      console.log('No user logged in');
      toast({
        title: 'Authentication required',
        description: 'Please log in to subscribe.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    try {
      console.log('Getting session data');
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) {
        console.error('Session error:', sessionError);
        throw sessionError;
      }
      console.log('Session data retrieved');

      console.log('Invoking create-checkout-session function');
      const response = await fetch('https://zexqcdauawzdtgauyles.supabase.co/functions/v1/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionData.session.access_token}`
        },
        body: JSON.stringify({ priceId: stripeProductId })
      });

      console.log('Response status:', response.status);
      console.log('Response headers:', Object.fromEntries(response.headers.entries()));

      const text = await response.text();
      console.log('Raw response:', text);

      let data;
      try {
        data = JSON.parse(text);
      } catch (e) {
        console.error('Error parsing JSON:', e);
        throw new Error('Invalid JSON response from server');
      }

      console.log('Parsed response:', data);

      if (!response.ok) {
        throw new Error(data.error || `HTTP error! status: ${response.status}`);
      }

      if (data && data.url) {
        console.log('Redirecting to:', data.url);
        window.location.href = data.url;
      } else {
        throw new Error('No checkout URL received');
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      toast({
        title: 'Error creating checkout session',
        description: error.message || 'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box maxW="1200px" mx="auto" py={8} px={isMobile ? 4 : 0}>
      <VStack spacing={8} align="stretch">
        <Box textAlign="center">
          <Heading as="h1" size={isMobile ? "lg" : "xl"} mb={2} color={colorMode === 'light' ? 'gray.800' : 'white'}>
            Choose Your Plan
          </Heading>
          <Text fontSize={isMobile ? "md" : "lg"} color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
            Select the perfect plan for your investment needs
          </Text>
        </Box>
        <SimpleGrid columns={[1, null, 3]} spacing={8}>
          {plans.map((plan) => (
            <PricingCard 
              key={plan.id}
              title={plan.name}
              price={plan.price / 100}
              features={plan.description.split(',')}
              isPopular={plan.metadata?.popular === 'true'}
              stripeProductId={plan.stripe_price_id}
              onSubscribe={handleSubscribe}
            />
          ))}
        </SimpleGrid>
      </VStack>
    </Box>
  );
};

export default Subscription;