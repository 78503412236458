import React, { useState, useEffect } from 'react';
import { Box, Heading, VStack, Select, Input, Button, Table, Thead, Tbody, Tr, Th, Td, Text, useBreakpointValue, Spinner, FormControl, FormLabel } from '@chakra-ui/react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { stockDataManager } from './mockData';

const Backtesting = () => {
  const [stockOptions, setStockOptions] = useState([]);
  const [selectedStock, setSelectedStock] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [strategy, setStrategy] = useState('buyAndHold');
  const [movingAveragePeriod, setMovingAveragePeriod] = useState(20);
  const [adtvThreshold, setAdtvThreshold] = useState(1000000);
  const [results, setResults] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [priceData, setPriceData] = useState([]);
  const [timeFilter, setTimeFilter] = useState('all');

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const initializeData = async () => {
      await stockDataManager.loadData();
      const stocks = stockDataManager.getStockList();
      setStockOptions(stocks);
      const { minDate, maxDate } = stockDataManager.getDateRange();
      setStartDate(minDate.toISOString().split('T')[0] || '');
      setEndDate(maxDate.toISOString().split('T')[0] || '');
      setIsLoading(false);
    };

    initializeData();
  }, []);

  useEffect(() => {
    if (selectedStock && startDate && endDate) {
      const fetchedPriceData = stockDataManager.getStockData(selectedStock, new Date(startDate), new Date(endDate));
      setPriceData(fetchedPriceData);
    }
  }, [selectedStock, startDate, endDate]);

  const handleStockChange = (event) => {
    setSelectedStock(event.target.value);
  };

  const calculateMovingAverage = (data, period) => {
    return data.map((row, index, array) => {
      if (index < period - 1) return { ...row, MA: null };
      const sum = array.slice(index - period + 1, index + 1).reduce((acc, curr) => acc + curr[selectedStock], 0);
      return { ...row, MA: sum / period };
    });
  };

  const filterDataByTime = (data) => {
    const now = new Date();
    switch (timeFilter) {
      case '1m':
        return data.filter(d => d.DATE >= new Date(now.setMonth(now.getMonth() - 1)));
      case '3m':
        return data.filter(d => d.DATE >= new Date(now.setMonth(now.getMonth() - 3)));
      case '6m':
        return data.filter(d => d.DATE >= new Date(now.setMonth(now.getMonth() - 6)));
      case '1y':
        return data.filter(d => d.DATE >= new Date(now.setFullYear(now.getFullYear() - 1)));
      default:
        return data;
    }
  };

  const runBacktest = () => {
    if (!selectedStock || !startDate || !endDate) {
      alert('Please select a stock and date range');
      return;
    }

    const filteredData = filterDataByTime(priceData);
    const dataWithMA = calculateMovingAverage(filteredData, movingAveragePeriod);

    let balance = 10000;
    let shares = 0;
    const tradeHistory = [];
    let inPosition = false;

    dataWithMA.forEach((day, index) => {
      const adtv = stockDataManager.calculateADTV(selectedStock, 30);
      const canTrade = adtv >= adtvThreshold;

      if (strategy === 'buyAndHold' && index === 0 && canTrade) {
        shares = Math.floor(balance / day[selectedStock]);
        balance -= shares * day[selectedStock];
        tradeHistory.push({ date: day.DATE, action: 'Buy', shares, price: day[selectedStock], balance });
        inPosition = true;
      } else if (strategy === 'movingAverage' && day.MA !== null && canTrade) {
        if (!inPosition && day[selectedStock] > day.MA) {
          shares = Math.floor(balance / day[selectedStock]);
          balance -= shares * day[selectedStock];
          tradeHistory.push({ date: day.DATE, action: 'Buy', shares, price: day[selectedStock], balance });
          inPosition = true;
        } else if (inPosition && day[selectedStock] < day.MA) {
          balance += shares * day[selectedStock];
          tradeHistory.push({ date: day.DATE, action: 'Sell', shares, price: day[selectedStock], balance });
          shares = 0;
          inPosition = false;
        }
      }
    });

    if (inPosition) {
      const lastDay = dataWithMA[dataWithMA.length - 1];
      balance += shares * lastDay[selectedStock];
      tradeHistory.push({ date: lastDay.DATE, action: 'Sell', shares, price: lastDay[selectedStock], balance });
    }

    const finalValue = balance;
    const totalReturn = ((finalValue - 10000) / 10000) * 100;

    setResults({
      tradeHistory,
      finalValue,
      totalReturn,
      performanceData: dataWithMA
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box maxW="1200px" mx="auto" py={8} px={isMobile ? 4 : 0}>
      <Heading as="h1" size="xl" mb={6}>
        Backtesting
      </Heading>
      <VStack spacing={4} align="stretch" mb={8}>
        <FormControl>
          <FormLabel>Select Stock</FormLabel>
          <Select value={selectedStock} onChange={handleStockChange} placeholder="Select stock">
            {stockOptions.map((stock) => (
              <option key={stock} value={stock}>
                {stock}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Start Date</FormLabel>
          <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </FormControl>
        <FormControl>
          <FormLabel>End Date</FormLabel>
          <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </FormControl>
        <FormControl>
          <FormLabel>Strategy</FormLabel>
          <Select value={strategy} onChange={(e) => setStrategy(e.target.value)}>
            <option value="buyAndHold">Buy and Hold</option>
            <option value="movingAverage">Moving Average Crossover</option>
          </Select>
        </FormControl>
        {strategy === 'movingAverage' && (
          <FormControl>
            <FormLabel>Moving Average Period</FormLabel>
            <Input 
              type="number" 
              value={movingAveragePeriod} 
              onChange={(e) => setMovingAveragePeriod(Number(e.target.value))}
              placeholder="Moving Average Period"
            />
          </FormControl>
        )}
        <FormControl>
          <FormLabel>ADTV Threshold</FormLabel>
          <Input 
            type="number" 
            value={adtvThreshold} 
            onChange={(e) => setAdtvThreshold(Number(e.target.value))}
            placeholder="ADTV Threshold"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Time Filter</FormLabel>
          <Select value={timeFilter} onChange={(e) => setTimeFilter(e.target.value)}>
            <option value="all">All Time</option>
            <option value="1m">Last Month</option>
            <option value="3m">Last 3 Months</option>
            <option value="6m">Last 6 Months</option>
            <option value="1y">Last Year</option>
          </Select>
        </FormControl>
        <Button onClick={runBacktest} colorScheme="blue" width="100%">
          Run Backtest
        </Button>
      </VStack>
      {results && (
        <Box>
          <Heading as="h2" size="lg" mb={4}>
            Backtest Results
          </Heading>
          <Text mb={2}>Final Value: ${results.finalValue.toFixed(2)}</Text>
          <Text mb={4}>Total Return: {results.totalReturn.toFixed(2)}%</Text>
          <Box height="400px" mb={8}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={results.performanceData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="DATE" tickFormatter={(date) => new Date(date).toLocaleDateString()} />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip labelFormatter={(date) => new Date(date).toLocaleDateString()} />
                <Legend />
                <Line yAxisId="left" type="monotone" dataKey={selectedStock} stroke="#8884d8" name="Stock Price" />
                {strategy === 'movingAverage' && (
                  <Line yAxisId="left" type="monotone" dataKey="MA" stroke="#82ca9d" name={`${movingAveragePeriod}-day MA`} />
                )}
              </LineChart>
            </ResponsiveContainer>
          </Box>
          <Box overflowX="auto">
            <Table variant="simple" size={isMobile ? "sm" : "md"}>
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>Action</Th>
                  <Th>Shares</Th>
                  <Th>Price</Th>
                  <Th>Balance</Th>
                </Tr>
              </Thead>
              <Tbody>
                {results.tradeHistory.map((trade, index) => (
                  <Tr key={index}>
                    <Td>{new Date(trade.date).toLocaleDateString()}</Td>
                    <Td>{trade.action}</Td>
                    <Td>{trade.shares}</Td>
                    <Td>${trade.price.toFixed(2)}</Td>
                    <Td>${trade.balance.toFixed(2)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Backtesting;