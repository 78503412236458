import React, { useState, useEffect } from 'react';
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td, Button, Input, VStack, HStack, Text, useBreakpointValue, Spinner, Checkbox, CheckboxGroup, Select } from '@chakra-ui/react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { stockDataManager } from './mockData';

const PortfolioAnalysis = () => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [stockOptions, setStockOptions] = useState([]);
  const [selectedStocks, setSelectedStocks] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [analysis, setAnalysis] = useState(null);
  const [weights, setWeights] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [comparisonStock, setComparisonStock] = useState('');

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const initializeData = async () => {
      await stockDataManager.loadData();
      const stocks = stockDataManager.getStockList();
      setStockOptions(stocks);
      const { minDate, maxDate } = stockDataManager.getDateRange();
      setStartDate(minDate.toISOString().split('T')[0] || '');
      setEndDate(maxDate.toISOString().split('T')[0] || '');
      setIsLoading(false);
    };

    initializeData();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  const handleStockSelection = (selectedStocks) => {
    setSelectedStocks(selectedStocks);
    const initialWeights = selectedStocks.reduce((acc, stock) => {
      acc[stock] = 1 / selectedStocks.length;
      return acc;
    }, {});
    setWeights(initialWeights);
  };

  const handleWeightChange = (stock, value) => {
    setWeights(prev => ({ ...prev, [stock]: parseFloat(value) }));
  };

  const handleAnalysis = () => {
    if (selectedStocks.length === 0) {
      alert('Please select at least one stock');
      return;
    }

    if (!startDate || !endDate) {
      alert('Please select a start and end date');
      return;
    }

    const totalWeight = Object.values(weights).reduce((sum, weight) => sum + weight, 0);
    if (Math.abs(totalWeight - 1) > 0.0001) {
      alert('The sum of weights must equal 1');
      return;
    }

    const data = stockDataManager.getStockData(selectedStocks[0], new Date(startDate), new Date(endDate));
    const compositeData = calculateCompositeValue(data);
    
    const stockAnalysis = selectedStocks.map(stock => {
      const returns = stockDataManager.calculateReturns(data, stock);
      const volatility = stockDataManager.calculateVolatility(returns);
      return {
        stock,
        returns: returns[returns.length - 1],
        volatility,
        weight: weights[stock]
      };
    });

    const portfolioReturn = stockAnalysis.reduce((sum, stock) => sum + stock.returns * stock.weight, 0);
    const portfolioVolatility = Math.sqrt(
      stockAnalysis.reduce((sum, stock1) => 
        sum + stockAnalysis.reduce((innerSum, stock2) => 
          innerSum + stock1.weight * stock2.weight * stock1.volatility * stock2.volatility * 
          (stock1.stock === stock2.stock ? 1 : 0.5), // Simplified correlation
        0), 
      0)
    );

    setAnalysis({
      stockAnalysis,
      portfolioReturn,
      portfolioVolatility,
      sharpeRatio: (portfolioReturn - 0.02) / portfolioVolatility // Assuming risk-free rate of 2%
    });

    setPortfolioData(compositeData);
  };

  const calculateCompositeValue = (data) => {
    return data.map(day => {
      const compositeValue = selectedStocks.reduce((sum, stock) => sum + day[stock] * weights[stock], 0);
      return { ...day, compositeValue };
    });
  };

  return (
    <Box maxW="1200px" mx="auto" py={8} px={isMobile ? 4 : 0}>
      <Heading as="h1" size="xl" mb={6}>Portfolio Analysis</Heading>
      <VStack spacing={4} align="stretch" mb={8}>
        <Box height="300px" overflowY="auto" borderWidth={1} borderRadius="md" p={2}>
          <CheckboxGroup colorScheme="blue" value={selectedStocks} onChange={handleStockSelection}>
            <VStack align="start" spacing={2}>
              {stockOptions.map((stock) => (
                <Checkbox key={stock} value={stock}>{stock}</Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>
        </Box>
        {selectedStocks.map(stock => (
          <HStack key={stock}>
            <Text width="100px">{stock}</Text>
            <Input
              type="number"
              step="0.01"
              value={weights[stock]}
              onChange={(e) => handleWeightChange(stock, e.target.value)}
            />
          </HStack>
        ))}
        <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        <Button onClick={handleAnalysis} colorScheme="blue" width="100%">Analyze Portfolio</Button>
      </VStack>
      {analysis && (
        <VStack spacing={6} align="stretch">
          <Box>
            <Heading as="h2" size="lg" mb={4}>Portfolio Summary</Heading>
            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Td>Portfolio Return</Td>
                  <Td>{(analysis.portfolioReturn * 100).toFixed(2)}%</Td>
                </Tr>
                <Tr>
                  <Td>Portfolio Volatility</Td>
                  <Td>{(analysis.portfolioVolatility * 100).toFixed(2)}%</Td>
                </Tr>
                <Tr>
                  <Td>Sharpe Ratio</Td>
                  <Td>{analysis.sharpeRatio.toFixed(2)}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
          <Box>
            <Heading as="h3" size="md" mb={2}>Stock Analysis</Heading>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Stock</Th>
                  <Th>Weight</Th>
                  <Th>Return</Th>
                  <Th>Volatility</Th>
                </Tr>
              </Thead>
              <Tbody>
                {analysis.stockAnalysis.map(stock => (
                  <Tr key={stock.stock}>
                    <Td>{stock.stock}</Td>
                    <Td>{(stock.weight * 100).toFixed(2)}%</Td>
                    <Td>{(stock.returns * 100).toFixed(2)}%</Td>
                    <Td>{(stock.volatility * 100).toFixed(2)}%</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box height="400px">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={portfolioData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="DATE" tickFormatter={(date) => date.toLocaleDateString()} />
                <YAxis />
                <Tooltip labelFormatter={(date) => date.toLocaleDateString()} />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="compositeValue" 
                  stroke="#8884d8" 
                  name="Composite Value"
                  strokeWidth={2}
                />
                {comparisonStock && (
                  <Line 
                    type="monotone" 
                    dataKey={comparisonStock} 
                    stroke="#82ca9d" 
                    name={comparisonStock}
                    dot={false}
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          </Box>
          <Select 
            placeholder="Select stock to compare" 
            value={comparisonStock} 
            onChange={(e) => setComparisonStock(e.target.value)}
          >
            {selectedStocks.map(stock => (
              <option key={stock} value={stock}>{stock}</option>
            ))}
          </Select>
        </VStack>
      )}
    </Box>
  );
};

export default PortfolioAnalysis;