import React, { useState } from 'react';
import { Box, Heading, Text, VStack, HStack, Button, Select, Input, useBreakpointValue } from '@chakra-ui/react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const demoData = [
  { date: '2023-01-01', AAPL: 150, GOOGL: 2800, MSFT: 220 },
  { date: '2023-02-01', AAPL: 155, GOOGL: 2850, MSFT: 225 },
  { date: '2023-03-01', AAPL: 160, GOOGL: 2900, MSFT: 230 },
  { date: '2023-04-01', AAPL: 165, GOOGL: 2950, MSFT: 235 },
  { date: '2023-05-01', AAPL: 170, GOOGL: 3000, MSFT: 240 },
];

const Demo = () => {
  const [selectedStock, setSelectedStock] = useState('AAPL');
  const [investmentAmount, setInvestmentAmount] = useState(10000);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleStockChange = (event) => {
    setSelectedStock(event.target.value);
  };

  const handleInvestmentChange = (event) => {
    setInvestmentAmount(parseFloat(event.target.value));
  };

  const calculateReturns = () => {
    const initialPrice = demoData[0][selectedStock];
    const finalPrice = demoData[demoData.length - 1][selectedStock];
    const shares = investmentAmount / initialPrice;
    const finalValue = shares * finalPrice;
    const returnPercentage = ((finalValue - investmentAmount) / investmentAmount) * 100;

    return {
      finalValue: finalValue.toFixed(2),
      returnPercentage: returnPercentage.toFixed(2),
    };
  };

  const returns = calculateReturns();

  return (
    <Box maxW="1200px" mx="auto" py={8} px={isMobile ? 4 : 0}>
      <Heading as="h1" size={isMobile ? "lg" : "xl"} mb={6}>
        Demo Portfolio Analysis
      </Heading>
      <VStack spacing={6} align="stretch">
        <VStack spacing={4} align="stretch">
          <Select value={selectedStock} onChange={handleStockChange}>
            <option value="AAPL">Apple (AAPL)</option>
            <option value="GOOGL">Google (GOOGL)</option>
            <option value="MSFT">Microsoft (MSFT)</option>
          </Select>
          <Input
            type="number"
            value={investmentAmount}
            onChange={handleInvestmentChange}
            placeholder="Investment Amount"
          />
        </VStack>
        <Box height={isMobile ? "300px" : "400px"}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={demoData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey={selectedStock} stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </Box>
        <Box borderWidth={1} borderRadius="lg" p={4}>
          <Heading as="h3" size="md" mb={2}>
            Investment Summary
          </Heading>
          <Text>Initial Investment: ${investmentAmount.toFixed(2)}</Text>
          <Text>Final Value: ${returns.finalValue}</Text>
          <Text>Return: {returns.returnPercentage}%</Text>
        </Box>
        <Button colorScheme="blue" size="lg">
          Start Your Free Trial
        </Button>
      </VStack>
    </Box>
  );
};

export default Demo;