import React, { useState } from 'react';
import { Box, Heading, VStack, FormControl, FormLabel, Switch, Select, Button, useToast, useBreakpointValue } from '@chakra-ui/react';

const Settings = () => {
  const [notifications, setNotifications] = useState(true);
  const [theme, setTheme] = useState('light');
  const [language, setLanguage] = useState('en');
  const toast = useToast();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleNotificationsChange = () => {
    setNotifications(!notifications);
  };

  const handleThemeChange = (event) => {
    setTheme(event.target.value);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const saveSettings = () => {
    // Here you would typically save the settings to a backend or local storage
    console.log('Saving settings:', { notifications, theme, language });
    toast({
      title: 'Settings saved',
      description: 'Your preferences have been updated.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box maxW="600px" mx="auto" py={8} px={isMobile ? 4 : 0}>
      <Heading as="h1" size={isMobile ? "lg" : "xl"} mb={6}>
        Settings
      </Heading>
      <VStack spacing={6} align="stretch">
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="notifications" mb="0">
            Enable Notifications
          </FormLabel>
          <Switch id="notifications" isChecked={notifications} onChange={handleNotificationsChange} />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="theme">Theme</FormLabel>
          <Select id="theme" value={theme} onChange={handleThemeChange}>
            <option value="light">Light</option>
            <option value="dark">Dark</option>
            <option value="system">System Default</option>
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="language">Language</FormLabel>
          <Select id="language" value={language} onChange={handleLanguageChange}>
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="fr">Français</option>
            <option value="de">Deutsch</option>
          </Select>
        </FormControl>

        <Button onClick={saveSettings} colorScheme="blue" width="100%">
          Save Settings
        </Button>
      </VStack>
    </Box>
  );
};

export default Settings;