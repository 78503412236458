import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient'; // Adjust this import path as needed
import { Box, Heading, Text, Spinner, Button, useToast } from '@chakra-ui/react';

const SubscriptionSuccess = () => {
  const [verificationStatus, setVerificationStatus] = useState('pending');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();

  const verifySubscription = async (sessionId) => {
    try {
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      const { data, error } = await supabase.functions.invoke('verify-subscription', {
        body: { sessionId, userId: sessionData.session.user.id },
      });

      if (error) throw error;
      
      if (data.success) {
        setVerificationStatus('success');
        toast({
          title: "Subscription Verified",
          description: "Your subscription has been successfully verified.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error('Failed to verify subscription');
      }
    } catch (error) {
      console.error('Error verifying subscription:', error);
      setError(error.message);
      setVerificationStatus('error');
      toast({
        title: "Verification Failed",
        description: error.message || "An error occurred while verifying your subscription.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');
    if (sessionId) {
      verifySubscription(sessionId);
    } else {
      setError('No session ID found');
      setVerificationStatus('error');
    }
  }, []);

  if (verificationStatus === 'pending') {
    return (
      <Box textAlign="center" py={10}>
        <Spinner size="xl" />
        <Text mt={4}>Verifying your subscription...</Text>
      </Box>
    );
  }

  if (verificationStatus === 'error') {
    return (
      <Box textAlign="center" py={10}>
        <Heading as="h2" size="xl" mb={4}>
          Subscription Verification Failed
        </Heading>
        <Text mb={4}>{error || 'An unknown error occurred'}</Text>
        <Button onClick={() => navigate('/subscription')}>
          Return to Subscription Page
        </Button>
      </Box>
    );
  }

  return (
    <Box textAlign="center" py={10}>
      <Heading as="h2" size="xl" mb={4}>
        Subscription Verified Successfully
      </Heading>
      <Text mb={4}>Thank you for subscribing!</Text>
      <Button onClick={() => navigate('/dashboard')}>
        Go to Dashboard
      </Button>
    </Box>
  );
};

export default SubscriptionSuccess;