import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider, Box } from '@chakra-ui/react';
import Header from './components/Header';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import Home from './pages/Home';
import PortfolioAnalysis from './pages/PortfolioAnalysis';
import DataVisualization from './pages/DataVisualization';
import Backtesting from './pages/Backtesting';
import Settings from './pages/Settings';
import Subscription from './pages/Subscription';
import SubscriptionSuccess from './pages/SubscriptionSuccess';
import Demo from './pages/Demo';

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Box minHeight="100vh" display="flex" flexDirection="column">
          <Header />
          <Navigation />
          <Box flex={1}>
            <Routes>
            <Route path="/" element={<Home />} />
                <Route path="/subscription" element={<Subscription />} />
                <Route path="/subscription/success" element={<SubscriptionSuccess />} />
              <Route path="/" element={<Home />} />
              <Route path="/portfolio" element={<PortfolioAnalysis />} />
              <Route path="/visualization" element={<DataVisualization />} />
              <Route path="/backtesting" element={<Backtesting />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/demo" element={<Demo />} />
            </Routes>
          </Box>
          <Footer />
        </Box>
      </Router>
    </ChakraProvider>
  );
}

export default App;